<template>
  <el-form
    ref="form"
    v-cus-loading="form.loading"
    :inline="true"
    :model="form.model"
    :rules="form.rules"
  >
    <el-form-item :label="$tc('person')">
      <select-person
        v-model="form.model.commonPersonId"
        type="customer"
        :clearable="true"
      ></select-person>
    </el-form-item>
    <el-form-item>
      <el-button
        size="mini"
        type="primary"
        @click="onSubmitPartial({ action: `${form.model.id}/person` })"
      >
        {{ $tc('save') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import form from '@/views/common/form'
import update from '@/views/common/update'
import modelCompany from './model'

export default {
  extends: update('companies'),
  props: {
    model: { required: false },
  },
  data() {
    return {
      form: form({
        model: modelCompany,
        rules: {
          commonPersonId: {
            required: true,
            message: this.$tc('required'),
            trigger: ['blur', 'change'],
          },
        },
      }),
    }
  },
  created() {
    this.form.model = this.model
  },
}
</script>
